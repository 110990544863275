import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel, EdriveSharedAuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SharedUserModel } from '../_models/shared-user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;  
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }
  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  /* # for Edrive Shared Serive  */
   currentSharedUserSubject: BehaviorSubject<SharedUserModel>;
  get currentSharedUserValue(): SharedUserModel {
    return this.currentSharedUserSubject.value;
  }
  set currentSharedUserValue(user: SharedUserModel) {
    this.currentSharedUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);    
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
    
    /* # for Edrive Shared Serive  */
    this.currentSharedUserSubject = new BehaviorSubject<SharedUserModel>(undefined);    
  }

  //#region about Methods for Edrive Shared Serive  
  // public methods
  edrive_shared_login(key: string, password: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.edrive_shared_login(key, password).pipe(
      map((auth: EdriveSharedAuthModel) => {
        const result = this.setAuthFromEdriveSharedAPI(auth);
        return result;
      }),
      switchMap(() => this.setSharedUserInfo()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  check_edrive_shared_key(key: string): Observable<any> {
    //this.isLoadingSubject.next(true);
    return this.authHttpService.check_edrive_shared_key(key).pipe(
      map((res: any) => {
        return res.valid;
      }),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }), finalize(() => {})
    )
  }

  edrive_shared_logout() {
    sessionStorage.removeItem('edrive-shared-client');    
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }
  
  setSharedUserInfo(): Observable<SharedUserModel> {
    const auth = this.getSharedUserFromSessionStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.createSharedUser(auth).pipe(
      map((user: SharedUserModel) => {
        if (user) {
          this.currentSharedUserSubject = new BehaviorSubject<SharedUserModel>(user);
        } else {
          this.edrive_shared_logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getSharedUserFromSessionStorage(): EdriveSharedAuthModel {
    try {
      const authData = JSON.parse(
        sessionStorage.getItem('edrive-shared-client')
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private setAuthFromEdriveSharedAPI(auth: EdriveSharedAuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      sessionStorage.setItem("edrive-shared-client", JSON.stringify(auth));
      return true;
    }
    return false;
  }
  //#endregion about Methods for Edrive Shared Serive

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.authToken).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.authToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        window.localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
